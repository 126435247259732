import { call, put } from 'redux-saga/effects';
import { goBack } from 'react-router-redux';
import firebase from 'firebase/app';

import { textToKey } from 'sava-shared/lib/utils/core';

import createSpeaker from '../../../sagas/user/speakers/create';
import { inMinutes, isOverlapping } from '../../../utils/time';

export default function* updateSession({
  conferenceHandle,
  id,
  sessionType,
  track,
  title,
  description,
  tags,
  speakers,
  day,
  time,
  video,
}) {
  const session = {
    sessionType,
    track,
    title,
    speakers: {},
    day,
    time,
  };

  if (tags) {
    session.tags = tags;
  }

  if (description) {
    session.description = description;
  }

  if (inMinutes(time.endTime) <= inMinutes(time.startTime)) {
    throw {
      code: 'session/duration',
      message: 'Session duration can not be 0 or negative.',
    };
  }

  if (sessionType.id) {
    session.sessionType = sessionType.id;
  } else {
    const key = textToKey(sessionType.name);

    yield firebase
      .database()
      .ref(`conferences/${conferenceHandle}/sessionTypes`)
      .update({ [key]: sessionType });

    session.sessionType = key;
  }

  if (track.id) {
    session.track = track.id;
  } else {
    const key = textToKey(track.name);

    yield firebase
      .database()
      .ref(`conferences/${conferenceHandle}/tracks`)
      .update({ [key]: track });

    session.track = key;
  }

  for (let i = 0, n = (speakers || []).length; i < n; i += 1) {
    const speaker = speakers[i];

    let speakerId = speaker.id;

    if (!speakerId) {
      speakerId = yield call(
        createSpeaker,
        conferenceHandle,
        speaker.name,
        speaker.headline,
        undefined,
        undefined,
        speaker.image,
        undefined,
        true
      );
    }

    session.speakers[speakerId] = true;
  }

  if (video) {
    session.video = video;
  } else {
    yield firebase
      .database()
      .ref(`conferences/${conferenceHandle}/sessions/${id}/video`)
      .remove();
  }

  yield firebase
    .database()
    .ref(`conferences/${conferenceHandle}/sessions`)
    .transaction(sessions => {
      const hasConflicts = Object.entries(sessions || {}).some(([_, s]) => {
        return _ === id
          ? false
          : s.day === session.day &&
              s.track === session.track &&
              isOverlapping(s.time, session.time);
      });

      if (hasConflicts) {
        throw {
          code: 'session/overlapping',
          message: 'Session overlaps with another session.',
        };
      }

      return Object.assign(sessions, {
        [id]: Object.assign(sessions[id], session),
      });
    });

  yield put(goBack());
}
